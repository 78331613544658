import './App.css';
import Nav from './Component/Js/Nav1'
import Nav2 from './Component/Js/Nav2';
import Who from './Component/Js/Who';
import Calling from './Component/Js/Calling';
import Location from './Component/Js/Location';
import Footer from './Component/Js/Footer';

function App() {
  return (
    <>
    <Nav/>
    <Nav2/>
    <Who/>
    <Calling/>
    <Location/>
    <Footer/>
    </>
  );
}

export default App;
