import React from 'react'
import '../Css/Who.css'

const Who = () => {
  return (
    <>
    <div className='content'>
    <h1 className='whoAre'>WHO ARE WE</h1>
      <div>
        <p className='line1'>William Carey University is a Christian university dedicated to guiding students in finding and achieving their <span className='line11'>higher calling</span>.</p>
        <p className='line2'>We believe in challenging students to excel in scholarship, leadership, and service in the global community, we believe in preparing students to fulfill their diverse callings and inspire positive change in the world around them. Our calling is to help you achieve yours.</p>
        
         <p className='line3'>Are you ready?</p>
         <button className='start'>Get Started </button>
       </div>
    </div>
    </>
  )
}

export default Who