import React from 'react';
import '../Css/Calling.css'; // Importing the CSS file

const Calling = () => {
  return (
    <div className="calling-container">
      <div className="text-content">
        <h1>Ready to find <span className="italic">your calling?</span></h1>
        <div className="button-container">
          <button className="apply-button">Apply Now</button>
          <button className="visit-button">Visit</button>
        </div>
      </div>
    </div>
  );
};

export default Calling;
