import React, { useState, useEffect } from 'react';
import '../Css/Nav2.css'; // Importing the CSS file
import will1 from '../asset/WillLogo.png'; // Importing the logo image
import banner1 from '../asset/willB1.jpg'; // Import your images for the banner
import banner2 from '../asset/willB2.jpg';
import banner3 from '../asset/willB3.jpg';

const Nav2 = () => {
  const images = [banner1, banner2, banner3];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false); // State to toggle the mobile menu

  // Change image every 1 second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [images.length]);

  // Toggle the mobile menu
  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      {/* Navigation Bar */}
      <nav className="nav-container">
        <div className="nav-logo">
          <img src={will1} alt="Logo" />
        </div>
        <div className={`nav-menu ${menuOpen ? 'open' : ''}`}>
          <ul>
            <li><a href="#">MENU</a></li>
            <li><a href="#">APPLY</a></li>
            <li><a href="#">VISIT</a></li>
            <li><a href="#">REQUEST INFO</a></li>
            <li><a href="#">GIVE BACK</a></li>
            <li><a href="#"><i className="fas fa-search"></i></a></li>
          </ul>
        </div>
        <div className="hamburger" onClick={handleMenuToggle}>
          <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
        </div>
      </nav>

      {/* Banner Section */}
      <section className="banner-container">
        <div className="banner-image">
          <img src={images[currentIndex]} alt={`Banner ${currentIndex + 1}`} />
        </div>
      </section>

      <div className='lastNav'>
        <div className='innerContent'>
            <div className='contents'>LEARN MORE ABOUT CAREY</div>
            <div className='contents'>EXPLORE DEGREE OPTIONS</div>
            <div className='contents'>SCHEDULE A VISIT</div>
        </div>
      </div>
    </>
  );
};

export default Nav2;
