import React from 'react'
import '../Css/Location.css'

const Location = () => {
  return (
    <>
    <h1 className='loca'>Our Location</h1>
    <div className='loc'>
        <p className='one'>Hattiesburg Campus</p>
        <p className='one'>Tradition Campus</p>
        <p className='one'>Baton Rough Site</p>
    </div>
    </>
  )
}

export default Location