import React from 'react';
import '../Css/Footer.css';

const Footer = () => {
  return (
    <div className="footer-container">
      <p>© 2024 William Carey University | All Rights Reserved | Policies & Statements</p>
    </div>
  );
};

export default Footer;
