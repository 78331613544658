import React from 'react';
import '../Css/Nav1.css';

const Nav = () => {
  return (
    <>
      <div className="custom-navigation">
        <div className="left-section">
          <a href="#content">Skip to content</a>
        </div>
        <div className="right-section">
          <a href="#about">About</a>
          <a href="#contact">Contact Us</a>
          <a href="tel:+123456789">Call</a>
        </div>
      </div>
    </>
  );
};

export default Nav;
